import React, { FunctionComponent } from 'react';
import { Button, Heading, Typography } from '@mycrypto/ui';
import Tab from '../Tab/Tab';
import Print from '../Print/Print';
import './Get.scss';

const Get: FunctionComponent = () => (
  <Tab color="#F9F9F9" centered={true}>
    <div className="get">
      <Heading className="get-header">Get Your Own Invisible Ink Paper Wallet For FREE*</Heading>
      <Print>
        <Button className="get-button">Get Yours Now</Button>
      </Print>
      <Typography className="get-disclaimer">
        *<i>limited time only. while supplies last.</i>
      </Typography>
    </div>
  </Tab>
);

export default Get;
