import React, { FunctionComponent } from 'react';
import { Heading, Typography } from '@mycrypto/ui';
import Tab from '../Tab/Tab';
import './FAQ.scss';

const FAQ: FunctionComponent = () => (
  <Tab color="#17324F">
    <div className="faq">
      <div className="faq-block">
        <Heading className="faq-header">Frequently asked questions</Heading>
      </div>
      <div className="faq-questions">
        <div className="faq-block">
          <div className="faq-subblock">
            <Typography className="question">Q: Do I need special ink to print this?</Typography>
            <Typography className="answer">
              A: Nope! Just click the <i>"Get It Now"</i> button and print away!
            </Typography>
          </div>
          <div className="faq-subblock">
            <Typography className="question">Q: Is this as safe as a hardware wallet?</Typography>
            <Typography className="answer">
              A: No, a paper wallet is one of the easiest ways to secure yourself but not the best.
              We always recommend a hardware wallet.
            </Typography>
          </div>
        </div>
        <div className="faq-block">
          <div className="faq-subblock">
            <Typography className="question">Q: How do I get ICO Saltwater?</Typography>
            <Typography className="answer">
              A: Head over to coinmarketcap and contact any of the tokens below the top #200 market
              cap.
            </Typography>
          </div>
          <div className="faq-subblock">
            <Typography className="question">Q: Is this a real product?</Typography>
            <Typography className="answer">A: No.</Typography>
          </div>
        </div>
      </div>
    </div>
  </Tab>
);

export default FAQ;
