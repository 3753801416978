import React, { FunctionComponent } from 'react';
import { Heading, Typography, List } from '@mycrypto/ui';
import Tab from '../Tab/Tab';
import './Reveal.scss';

const Reveal: FunctionComponent = () => (
  <Tab color="#F8F8F8">
    <div className="reveal">
      <Heading className="reveal-header">Need to reveal your private key?</Heading>
      <Typography className="reveal-content">
        We don't recommend attempting that but if you really need to, you can douse the Invisible
        Ink Paper Wallet in any of the following liquids:
      </Typography>
      <div className="liquids">
        <div className="liquids-block">
          <List inline={true}>{['ICO Saltwater', 'CryptoKitty Urine']}</List>
        </div>
        <div className="liquids-block">
          <List inline={true}>{['Unicorn tears', 'EVM Oil']}</List>
        </div>
        <div className="liquids-block">
          <List inline={true}>{['Headlight fluid']}</List>
        </div>
      </div>
    </div>
  </Tab>
);

export default Reveal;
