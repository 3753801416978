import React, { FunctionComponent } from 'react';
import { Typography } from '@mycrypto/ui';
import Tab from '../Tab/Tab';
import Thief from '../../images/thief.svg';
import World from '../../images/world.svg';
import './Features.scss';

const Features: FunctionComponent = () => (
  <Tab color="#1097C0">
    <div className="features">
      <div className="features-block">
        <Typography className="features-block-1">
          The paper wallet you know and love, reimagined for enhanced security and privacy - with
          invisible ink.
        </Typography>
      </div>
      <div className="features-block" style={{ backgroundColor: 'white' }}>
        <img className="features-image" src={Thief} />
        <Typography className="features-description">
          They can't steal your private key if they can't see it.
        </Typography>
      </div>
      <div className="features-block" style={{ backgroundColor: 'white' }}>
        <img className="features-image" src={World} />
        <Typography className="features-description">
          Store it literally anywhere! It doesn't matter!
        </Typography>
      </div>
    </div>
  </Tab>
);

export default Features;
