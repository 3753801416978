import React, { FunctionComponent } from 'react';
import MyCrypto from '../../images/mycrypto.svg';
import { Typography } from '@mycrypto/ui';
import Tab from '../Tab/Tab';
import './Header.scss';

const Header: FunctionComponent = () => (
  <Tab color="#17324F">
    <div className="header">
      <img width="42" height="42" src={MyCrypto} />{' '}
      <Typography className="header-text">MyCrypto</Typography>
    </div>
  </Tab>
);

export default Header;
