import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import * as preview from '../../images/preview.png';

const MetaData: FunctionComponent = () => (
  <StaticQuery
    query={graphql`
      query PageData {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => (
      <Helmet>
        <title>{siteMetadata.title}</title>
        <meta name="apple-mobile-web-app-title" content={siteMetadata.title} />
        <meta name="description" content={siteMetadata.description} />
        <meta property="og:title" content={siteMetadata.title} />
        <meta property="og:site_name" content={siteMetadata.title} />
        <meta property="og:description" content={siteMetadata.description} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content={`${siteMetadata.siteUrl}${preview}`} />
        <meta name="twitter:title" content={siteMetadata.title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MyCrypto" />
        <meta name="twitter:creator" content="@MyCrypto" />
        <meta name="twitter:image" content={`${siteMetadata.siteUrl}${preview}`} />
        <meta name="theme-color" content="#1097C0" />
        <meta name="mobile-web-app-capable" content="yes" />
      </Helmet>
    )}
  />
);

export default MetaData;
