import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children?: ReactNode;
  color?: string;
  centered?: boolean;
}

const TabWrapper = styled.div`
  background-color: ${(props: Props) => (props.color ? props.color : 'inherit')};
  text-align: ${(props: Props) => (props.centered ? 'center' : 'inherit')};
`;

const Tab: FunctionComponent<Props> = ({ children, color, centered }: Props) => (
  <TabWrapper color={color} centered={centered}>
    {children}
  </TabWrapper>
);

export default Tab;
