import React from 'react';
import { Helmet } from 'react-helmet';
import { light } from '@mycrypto/ui';
import { ThemeProvider } from 'styled-components';

import MetaData from '../components/MetaData/MetaData';
import Header from '../components/Header/Header';
import Introduction from '../components/Introduction/Introduction';
import Features from '../components/Features/Features';
import Get from '../components/Get/Get';
import Instructions from '../components/Instructions/Instructions';
import Reveal from '../components/Reveal/Reveal';
import FAQ from '../components/FAQ/FAQ';
import Footer from '../components/Footer/Footer';

import consoleMessage from '../utils/consoleMessage';

import '../sass/index.scss';

const Index: React.StatelessComponent = () => (
  <ThemeProvider theme={light}>
    <React.Fragment>
      <MetaData />
      <Header />
      <Introduction />
      <Features />
      <Get />
      <Instructions />
      <Reveal />
      <FAQ />
      <Footer />
    </React.Fragment>
  </ThemeProvider>
);

consoleMessage();

export default Index;
