import React, { FunctionComponent } from 'react';
import MyCrypto from '../../images/mycrypto.svg';
import { Typography } from '@mycrypto/ui';
import Tab from '../Tab/Tab';
import ExternalLink from '../ExternalLink/ExternalLink';
import './Footer.scss';

interface Link {
  link: string;
  text: string;
}

const SOCIAL_MEDIA_LINKS: Link[] = [
  {
    link: 'https://twitter.com/mycrypto',
    text: 'twitter'
  },
  {
    link: 'https://www.facebook.com/mycryptoHQ/',
    text: 'facebook'
  },
  {
    link: 'https://medium.com/@mycrypto',
    text: 'medium'
  },
  {
    link: 'https://www.linkedin.com/company/mycrypto',
    text: 'linkedin'
  },
  {
    link: 'https://github.com/MyCryptoHQ',
    text: 'github'
  },
  {
    link: 'https://www.reddit.com/r/mycrypto/',
    text: 'reddit'
  },
  {
    link: 'https://discord.gg/VSaTXEA',
    text: 'discord'
  }
];

const Footer: FunctionComponent = () => (
  <Tab color="#17324F">
    <div className="footer">
      <img width="42" height="42" src={MyCrypto} />{' '}
      <Typography className="footer-text">MyCrypto</Typography>
      <div className="socials">
        {SOCIAL_MEDIA_LINKS.map((socialMediaLink, index) => (
          <ExternalLink key={index} className="social-media-link" to={socialMediaLink.link}>
            <i className={`sm-icon sm-logo-${socialMediaLink.text}`} />
          </ExternalLink>
        ))}
      </div>
    </div>
  </Tab>
);

export default Footer;
