import React, { FunctionComponent } from 'react';
import { Button, Heading, Typography } from '@mycrypto/ui';
import Tab from '../Tab/Tab';
import Paper from '../../images/paper.png';
import Print from '../Print/Print';
import './Introduction.scss';

const Introduction: FunctionComponent = () => (
  <Tab color="#17324F">
    <div className="introduction">
      <div className="introduction-text">
        <Typography className="introduction-subheader">Introducing the all-new</Typography>
        <Heading className="introduction-header">Invisible Ink Paper Wallet</Heading>
        <Print>
          <Button className="introduction-button">Get It Now</Button>
        </Print>
      </div>
      <div className="introduction-image">
        <img src={Paper} />
      </div>
    </div>
  </Tab>
);

export default Introduction;
