// prettier-ignore
export default () => {
    console.log(
      `%c
  ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
  ┃                                                                                                  ┃
  ┃               ,:;;;;;;;;;;;,                                                                     ┃
  ┃            ;;;;;;;;;;;;;;;;;;;;;                                                                 ┃
  ┃         ;;;;;;;;;;;;;;;;;;;;;;;;;;,                                                              ┃
  ┃       ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;.                                                            ┃
  ┃      ;;;;;;;;;;'';;;;;;;;;'';;;;;;;;;;                           _ _   ______          _     _   ┃
  ┃    .;;;;;;;;:      ;;;;;      :;;;;;;;;         /\\              (_) | |  ____|        | |   | |  ┃
  ┃    ;;;;;;;:          '          ;;;;;;;;       /  \\   _ __  _ __ _| | | |__ ___   ___ | |___| |  ┃
  ┃   ;;;;;;;     ;;;;       ;;;;     ;;;;;;:     / /\\ \\ | '_ \\| '__| | | |  __/ _ \\ / _ \\| / __| |  ┃
  ┃  ';;;;;;;   ;;;;;;;;   ;;;;;;;;   ;;;;;;;    / ____ \\| |_) | |  | | | | | | (_) | (_) | \\__ \\_|  ┃
  ┃  :;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;   /_/    \\_\\ .__/|_|  |_|_| |_|  \\___/ \\___/|_|___(_)  ┃
  ┃  ,;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;             | |                                        ┃
  ┃   ;;;;;;;;;;;;   ;;;;;;;;;   ;;;;;;;;;;;;             |_|      ____   ___  _  ___                ┃
  ┃   ;;;;;;;;;;;;     ;;;;;     ;;;;;;;;;;;,                     |___ \\ / _ \\/ |/ _ \\               ┃
  ┃    ;;;;;;;;;;;;:           :;;;;;;;;;;;;                        __) | | | | | (_) |              ┃
  ┃     ;;;;;;;;;;;;;:       :;;;;;;;;;;;;;                        / __/| |_| | |\\__, |              ┃
  ┃      ;;;;;;;;;;;;;;:   :;;;;;;;;;;;;;;                        |_____|\\___/|_|  /_/               ┃
  ┃       ';;;;;;;;;;;;;;;;;;;;;;;;;;;;;                                                             ┃
  ┃         ';;;;;;;;;;;;;;;;;;;;;;;;;                                                               ┃
  ┃            ';;;;;;;;;;;;;;;;;;;'                                                                 ┃
  ┃                 :;;;;;;;;;:                                                                      ┃
  ┃                                                                                                  ┃
  ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
  `,
      'background: #163151; color: #FFF;'
    );
  }
