import React, { FunctionComponent } from 'react';
import { Heading, Typography } from '@mycrypto/ui';
import Tab from '../Tab/Tab';
import Arrow from '../../images/arrow.svg';
import Computer from '../../images/computer.svg';
import Printer from '../../images/printer.svg';
import Paper from '../../images/paper.svg';
import './Instructions.scss';

const Instructions: FunctionComponent = () => (
  <Tab centered={true}>
    <div className="instructions">
      <Heading className="instructions-header">Instructions</Heading>
      <table>
        <tbody>
          <tr>
            <td>
              <img className="instructions-image" src={Computer} />
            </td>
            <td>
              <img className="instructions-arrow" src={Arrow} />
            </td>
            <td>
              <img className="instructions-image" src={Printer} />
            </td>
            <td>
              <img className="instructions-arrow" src={Arrow} />
            </td>
            <td>
              <img className="instructions-image" src={Paper} />
            </td>
          </tr>
          <tr>
            <td>
              <Typography className="instructions-text">MyCrypto.com</Typography>
            </td>
            <td />
            <td>
              <Typography className="instructions-text">Printer</Typography>
            </td>
            <td />
            <td>
              <Typography className="instructions-text">Invisible Ink Paper Wallet</Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Tab>
);

export default Instructions;
